:root {
  --text-color: rgba(255, 255, 255, 0.7);
  --active-color: white;
  --background-color: #0e0e0e;
}

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  background: var(--background-color);
}

body {
  color: white;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
}

.container {
  display: flex;
  max-width: 1224px;
  padding: 2rem 0 0;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

/* hero */

.hero {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  aspect-ratio: 4 / 1;
  animation: videoColors 20s alternate infinite;
}

.hero::before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      10deg,
      hsla(184deg, 94%, 55%, 0.7) 0%,
      hsla(220deg, 94%, 55%, 0.7) 25%,
      hsla(0deg, 0%, 100%, 0.1) 50%,
      hsla(300deg, 94%, 55%, 0.7) 75%,
      hsla(320deg, 100%, 50%, 0.7) 100%
  );
}

.hero figure {
  width: 100%;
}

.hero video {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: -1;
  filter: brightness(120%) contrast(200%);
  object-fit: cover;
}

.hero svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(0, 0, 0, 0.5) 0.5px, transparent 0.5px);
  background-size: 2px 2px;
}

@media screen and (min-width: 768px) {
  .hero svg {
    background: radial-gradient(rgba(0, 0, 0, 0.5) 1px, transparent 1px);
    background-size: 3px 3px;
  }
}

@keyframes videoColors {
  from {
    filter: hue-rotate(0deg);
  }

  to {
    filter: hue-rotate(-50deg);
  }
}

/* contact section */

.contact p {
  text-align: center;
  font-size: 0.8rem;
  font-weight: 200;
  margin-bottom: 0.6rem;
  color: var(--text-color);
}

.contact ul {
  list-style: none;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 0;
}

.contact a {
  text-decoration: none;
  padding: 0.5rem;
  color: var(--text-color);
}

.contact a:hover, section a:focus {
  color: var(--active-color);
}

/* payoff */

.payoff {
  font-weight: 400;
  font-size: 1.5rem;
  margin: 0.6rem 0 1.2rem;
  color: var(--active-color);
}

@media screen and (min-width: 768px) {
  .payoff {
    margin: 0 0 1.2rem;
    font-size: 2.2rem
  }
}

/* band members */

.band-members {
  display: flex;
  font-weight: 200;
  font-size: 0.8rem;
  width: 100%;
  gap: 0.6rem;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 0.5rem;
  color: var(--text-color);
}

.band-members li {
  display: inline-flex;
  align-items: center;
  list-style: none
}

.band-members li:not(:first-of-type):before {
  content: '•';
  padding-right: 0.6rem;
}


@media screen and (min-width: 768px) {
  .band-members {
    font-size: 1rem;
    gap: 1rem;
  }

  .band-members li:not(:first-of-type):before {
    content: '•';
    padding-right: 1rem;
  }
}
